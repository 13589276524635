import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { instance } from "utils/axios";

const News = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/news`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching news:", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <section>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Splide
          options={{
            rewind: true,
            pagination: false,
            height: "50vh",
          }}
          aria-label="News Slide"
        >
          {data.map((newsItem, index) => (
            <SplideSlide className="slide grid grid-cols-12" key={index}>
              <div className="relative bg-[#C6E1FF60] md:bg-[#C6E1FF] md:col-span-5 col-span-8 flex justify-center items-center">
                <div className="w-full md:w-2/3 px-6">
                  <h1 className="slide-text text-secondary font-bold  text-xl md:text-3xl">
                    {newsItem.title}
                  </h1>
                  {/* <p className="slide-text mt-2 pb-4 leading-none font-medium line-clamp-2">
                    {newsItem.content.substring(0, 100)}
                  </p> */}
                  <Link
                    to={`news/${newsItem.slug}`}
                    className="slide-text hidden md:block mt-8 rounded-xl bg-primary font-semibold text-white px-6 py-2 text-center"
                  >
                    Дэлгэрэнгүй
                  </Link>
                </div>
              </div>
              <div className="col-span-4 md:col-span-7 overflow-hidden">
                <img
                  className="object-cover h-full md:w-full"
                  src={`${process.env.REACT_APP_CDN_URL}${
                    newsItem.thumbnail || "https://via.placeholder.com/800"
                  }`}
                  alt={newsItem.title}
                />
              </div>
            </SplideSlide>
          ))}
        </Splide>
      )}
    </section>
  );
};

export default News;
