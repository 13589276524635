import Layout from "layout";
import { createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import Employees from "./Employees";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import NewsDetail from "./NewsDetail";
import Products from "./Products";
import Services from "./Services";
import ServiceDetail from "./ServiceDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: 'doctors',
        element: <Employees/>
      },
      {
        path: 'contact-us',
        element: <ContactUs/>
      },
      {
        path: 'about-us',
        element: <AboutUs/>
      },
      {
        path: 'products',
        element: <Products/>
      },
      {
        path: 'news/:id',
        element: <NewsDetail/>
      },
      {
        path: 'services',
        element: <Services/>
      },
      {
        path: 'services/:slug',
        element: <ServiceDetail/>
      }
    ]
  },
  {
    path: "about",
    element: <div>About</div>,
  },
]);

export default router