import { useParams } from "react-router-dom"

import React, { useEffect, useState } from "react";
import { instance } from "utils/axios";
const NewsDetail = () => {
  const { id } = useParams()
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    console.log(id)
    instance({
      method: "get",
      url: `/news/${id}`,
    })
      .then((res) => {
      console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.error("Error fetching news:", err);
      })
      .finally(() => setLoading(false));
  };
  
  return (
    <div>
      <section className="py-10 bg-primary">
      
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">
         {data?.title}
          </div>
        </div>
      </section>
      <div className="py-10">
        <div className="container mx-auto p-6 bg-white rounded-xl shadow-md">
          <div dangerouslySetInnerHTML={{__html: data?.content}}>

          </div>
        </div>
      </div>)
    </div>
  )
}

export default NewsDetail