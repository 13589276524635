import AboutUs from "./components/AboutUs"
import Carousel from "./components/Carousel"
import JoinUs from "./components/JoinUs"
import Map from "./components/Map"
import News from "./components/News"
import Partners from "./components/Partners"
import Products from "./components/Products"
import Services from "./components/Services"
import Operation from "./components/Operation";

const Home = () => {
  return (
    <div>
      <Carousel></Carousel>
      <AboutUs></AboutUs>
      <Operation></Operation>
      <Services></Services>

      {/* <JoinUs>

      </JoinUs> */}
      {/* <Products></Products> */}
      <News></News>
      <Partners></Partners>
      <Map></Map>
    </div>
  );
}

export default Home