import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import 'assets/css/index.css';
import '@splidejs/react-splide/css';

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
