import { CiMail, CiMap, CiPhone  } from "react-icons/ci";

const ContactUs = () => {
  return (
    <div>
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">
            Таньд асуулт байна уу? <br />
            Бидэнтэй холбогдоод мэрэгжилтэнгүүдээс асуугаарай.
          </div>
        </div>
      </section>
      <section className="py-10">
        <div className="container mx-auto px-6 grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-8">
            <form className="grid grid-cols-12 gap-3">
              <div className="col-span-12">
                <legend className="block mb-2 text-sm font-medium text-gray-900 ">
                  Та аль нь вэ
                </legend>
                <div className="flex gap-3">
                  <label for="doctor" className="text-sm">
                    Эмч:{" "}
                  </label>
                  <input
                    type="radio"
                    id="doctor"
                    name="type"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  />
                </div>
                <div className="flex gap-3">
                  <label for="patient" className="text-sm">
                    Үйлчлүүлэгч:{" "}
                  </label>
                  <input
                    type="radio"
                    id="patient"
                    name="type"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  />
                </div>
              </div>
              <div className="mb-5 col-span-6">
                <label
                  for="lastName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Овог
                </label>
                <input
                  id="lastName"
                  name="lastName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Овог"
                  required
                />
              </div>
              <div className="mb-5 col-span-6">
                <label
                  for="firstName"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Нэр
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Нэр"
                  required
                />
              </div>
              <div className="mb-5 col-span-6">
                <label
                  for="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Утас
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  pattern="[0-9]{8}"
                />
              </div>
              <div className="mb-5 col-span-6">
                <label
                  for="email"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Имэйл
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div className="mb-5 col-span-12">
                <label
                  for="description"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Дэлгэрэнгүй тайлбар
                </label>
                <textarea
                  rows={4}
                  id="description"
                  name="description"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <button
                type="submit"
                className="text-white col-span-12 bg-primary hover:bg-primary/90 focus:ring-4 focus:outline-none focus:ring-primary/50 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="col-span-12 md:col-span-4 flex flex-col gap-3">
            <div className="bg-white shadow rounded-xl flex items-center p-6 gap-3">
              <div className="rounded-full p-4 border border-primary">
                <CiMail className="text-3xl text-primary" />
              </div>
              <div className="flex-1">
                <div className="font-bold text-xl mb-3">Утас</div>
                <div> 9403-4075</div>
              </div>
            </div>
            <div className="bg-white shadow rounded-xl flex items-center p-6 gap-3">
              <div className="rounded-full p-4 border border-primary">
                <CiPhone className="text-3xl text-primary" />
              </div>
              <div className="flex-1">
                <div className="font-bold text-xl mb-3">Имэйл</div>
                <div> tsahim.durs@gmail.com</div>
              </div>
            </div>
            <div className="bg-white shadow rounded-xl flex items-center p-6 gap-3">
              <div className="rounded-full p-4 border border-primary">
                <CiMap className="text-3xl text-primary" />
              </div>
              <div className="flex-1">
                <div className="font-bold text-xl md:text-2xl mb-3">Хаяг</div>
                <div>
                  Монгол улс, Улаанбаатар хот-210349, Баянзүрх дүүрэг, Энхтайвны
                  өргөн чөлөө, Засгийн газрын IX байр,
                  <br /> Төрийн худалдан авах ажиллагааны газар
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs