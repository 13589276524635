import Footer from "./components/Footer"
import Header from "./components/Header"
import { Outlet } from "react-router-dom"

const Layout = ({children}) => {
  return (
    <main>
      <Header></Header>
      <div id="content-container">
        <Outlet></Outlet>
      </div>
      <Footer></Footer>
    </main>
  )
}

export default Layout