import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import logo from "../../assets/image/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [ open, setOpen ] = useState(false)
  const [ dropdownOpen, setDropdownOpen ] = useState(false)
  const [ mobileDropdownOpen, setMobileDropdownOpen ] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setOpen(false)
    setDropdownOpen(false)
    setMobileDropdownOpen(false)
  }, [location])
  
  return (
    <header className="bg-white sticky top-0 border-b shadow-sm py-2 z-10">
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between gap-3">
          <NavLink to="/">
            <img src={logo} style={{ height: 60 }} />
          </NavLink>
          <div className="flex-1 gap-3 items-center justify-center hidden md:flex">
            <NavLink
              to="/"
              className={({ isActive }) =>
                twMerge(
                  "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                  isActive && "bg-slate-100 text-primary"
                )
              }
            >
              Нүүр
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                twMerge(
                  "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                  isActive && "bg-slate-100 text-primary"
                )
              }
            >
              Бидний тухай
            </NavLink>
            <NavLink
              to="/doctors"
              className={({ isActive }) =>
                twMerge(
                  "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                  isActive && "bg-slate-100 text-primary"
                )
              }
            >
              Эмч нар
            </NavLink>
            <div className="relative">
              <button
                className="py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary flex items-center"
                type="button"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Үйлчилгээ
                <svg
                  className="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <div
                id="dropdown"
                className={twMerge(
                  "z-10 divide-y divide-gray-100 rounded-lg shadow w-44 bg-white",
                  dropdownOpen ? "absolute" : "hidden"
                )}
              >
                <ul
                  className="py-2 text-sm text-gray-700 "
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <NavLink
                      to="/services/mri"
                      className="block px-4 py-2 hover:bg-gray-100 "
                    >
                      MRI Online Reporting
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/foo2"
                      className="block px-4 py-2 hover:bg-gray-100 "
                    >
                      {" "}
                      CT Online Reporting
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/foo3"
                      className="block px-4 py-2 hover:bg-gray-100 "
                    >
                      X-ray/Radiograph Online Reporting Service
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/services/foo4"
                      className="block px-4 py-2 hover:bg-gray-100 "
                    >
                     Pacs
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <NavLink
              to="/contact-us"
              className={({ isActive }) =>
                twMerge(
                  "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                  isActive && "bg-slate-100 text-primary"
                )
              }
            >
              Холбоо барих
            </NavLink>
          </div>
          {/* <button className="hover:bg-primary hover:text-white transition-all px-3 py-1 rounded-xl hidden md:block">
            Бүртгүүлэх
          </button> */}
          <button
            className="block md:hidden z-10 text-2xl"
            onClick={() => setOpen(!open)}
          >
            <GiHamburgerMenu />
          </button>
          <div
            className={twMerge(
              "flex md:hidden fixed w-screen h-screen bg-white left-0 transition-all py-10 overflow-auto",
              open ? "bottom-0" : "bottom-full"
            )}
          >
            <div className="flex-1 gap-3 items-center justify-center flex flex-col">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  twMerge(
                    "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                    isActive && "bg-slate-100 text-primary"
                  )
                }
              >
                Нүүр
              </NavLink>
              <NavLink
                to="/about-us"
                className={({ isActive }) =>
                  twMerge(
                    "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                    isActive && "bg-slate-100 text-primary"
                  )
                }
              >
                Бидний тухай
              </NavLink>
              <NavLink
                to="/doctors"
                className={({ isActive }) =>
                  twMerge(
                    "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                    isActive && "bg-slate-100 text-primary"
                  )
                }
              >
                Эмч нар
              </NavLink>
              <NavLink
                to="/contact-us"
                className={({ isActive }) =>
                  twMerge(
                    "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
                    isActive && "bg-slate-100 text-primary"
                  )
                }
              >
                Холбоо барих
              </NavLink>
              <div>
                <button
                  onClick={() => setMobileDropdownOpen(!mobileDropdownOpen)}
                  type="button"
                  className={
                    "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary flex items-center"
                  }
                >
                  Үйлчилгээ
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <div
                  id="dropdown"
                  className={twMerge(
                    "z-10 divide-y divide-gray-100 transition-all overflow-hidden",
                    mobileDropdownOpen ? "h-full" : "h-0"
                  )}
                >
                  <ul
                    className="py-2 text-sm text-gray-700 "
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <NavLink
                        to="/services/foo1"
                        className="block px-4 py-2 hover:bg-gray-100 "
                      >
                        foo1
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/services/foo2"
                        className="block px-4 py-2 hover:bg-gray-100 "
                      >
                        foo2
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/services/foo3"
                        className="block px-4 py-2 hover:bg-gray-100 "
                      >
                        foo3
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/services/foo4"
                        className="block px-4 py-2 hover:bg-gray-100 "
                      >
                        foo4
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <button className="hover:bg-primary hover:text-white transition-all px-3 py-1 rounded-xl">
                Бүртгүүлэх
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header