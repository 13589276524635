import { ReactComponent as MapSVG } from '../../../assets/image/mn.svg'

const Map = () => {
  return (
    <div className="container mx-auto px-6 pb-10">
      <div className="grid grid-cols-3">
        <div className="col-span-3 xl:col-span-2">
          <MapSVG className="w-full">asdf</MapSVG>
        </div>
        <div className="col-span-3 xl:col-span-1">
          <div className="uppercase text-primary text-sm text-center xl:text-left">
            Цар хүрээ
          </div>
          <div className="capitalize font-semibold text-2xl mb-10 text-center xl:text-left">
           Монгол улс
          </div>
          <div className="">
            <ul>
              <li>Говь-Алтай</li>
              <li>Төв</li>
              <li>Сүхбаатар</li>
              <li>Өмнөговь</li>
              <li>Баянхонгор</li>
              <li>Ховд</li>
              <li>Булган</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Map