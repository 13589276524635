import React, { useEffect, useState, useMemo } from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { twMerge } from "tailwind-merge";
import { instance } from "utils/axios";
const Employees = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/doctor/list`,
    })
      .then((res) => {
        console.log(res.data)
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  // const [ data, setData ] = useState(new Array(50).fill({
  //   img: 'https://via.placeholder.com/150',
  //   name: 'John Doe',
  //   exp: 2,
  //   specText: 'Radiology'
  // }))
  const [searchValue, setSearchValue] = useState({
    searchText: "",
    specText: "",
    exp: 0,
  });
  const [pagination, setPagination] = useState(0);

  const filteredData = useMemo(() => {
    let tmp = Object.assign([], data);

    if (searchValue.searchText.length > 0) {
      tmp = tmp.filter((foo) =>
        foo.name.toLowerCase().includes(searchValue.searchText)
      );
    }
    if (searchValue.specText.length > 0) {
      tmp = tmp.filter((foo) =>
        foo.specText.toLowerCase().includes(searchValue.specText)
      );
    }
    if (searchValue.exp != 0) {
      tmp = tmp.filter((foo) => foo.exp > searchValue.exp);
    }

    return tmp;
  }, [data, searchValue]);

  return (
    <div>
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">
          Манай эмч нар
          </div>
        </div>
      </section>
      <section className="py-10 container mx-auto px-6 grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-3 flex flex-col gap-3">
          <div className="bg-white shadow p-3 rounded-xl text-gray-900">
            Нийт {data.length} эмч
          </div>
          <div className="bg-white shadow p-3 rounded-xl">
            <div className="mb-5">
              <label
                for="search"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Нэр
              </label>
              <input
                value={searchValue.searchText}
                onChange={(e) =>
                  setSearchValue((prev) => ({
                    ...prev,
                    searchText: e.target.value,
                  }))
                }
                id="search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Хайх нэр"
              />
            </div>
            <div className="mb-5">
              <label
                for="spec"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Мэрэгшил
              </label>
              <input
                value={searchValue.specText}
                onChange={(e) =>
                  setSearchValue((prev) => ({
                    ...prev,
                    specText: e.target.value,
                  }))
                }
                id="spec"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Хайх мэрэгшил"
              />
            </div>
            <div>
              <label
                for="exp"
                class="block mb-2 text-sm font-medium text-gray-900"
              >
                Туршлага
              </label>
              <select
                value={searchValue.exp}
                onChange={(e) =>
                  setSearchValue((prev) => ({ ...prev, exp: e.target.value }))
                }
                id="exp"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Туршлага"
              >
                <option value={0}>Бүгд</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3+</option>
              </select>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-9 grid grid-cols-1 md:grid-cols-3 gap-3">
          {filteredData
            .slice(pagination * 12, pagination * 12 + 12)
            .map((foo, fooIndex) => {
              return (
                <div
                  className="shadow rounded-xl p-3 bg-white"
                  key={`doctor-${fooIndex}`}
                >
                  <div className="flex justify-center items-center">
                    <img
                      className="h-24 w-24 rounded-full object-cover object-center"
                      style={{ objectPosition: "center 10%" }}
                      src={`${process.env.REACT_APP_CDN_URL}${foo.imageUrl}`}
                    ></img>
                  </div>
                  <div className="text-center text-gray-900 font-bold">
                    {foo.firstName}
                  </div>
                  <div className="text-center text-gray-900 text-sm">
                    {foo.position}
                  </div>
                  <div className="text-center text-gray-900 text-sm">
                    {foo.description}
                  </div>
                  <div className="flex justify-center gap-3 mt-10">
                    <button className="py-2 px-6 bg-primary text-white rounded-xl hover:bg-primary/90">
                      Холбогдох
                    </button>
                  </div>
                </div>
              );
            })}
          <div className="col-span-1 md:col-span-3 flex justify-end place-items-center gap-3">
            <button
              onClick={() => setPagination(pagination - 1)}
              disabled={pagination == 0}
              className={twMerge(
                "hover:text-gray-900 transition-all",
                pagination == 0 && "text-gray-400 hover:text-gray-400"
              )}
            >
              <FaChevronCircleLeft className="text-3xl" />
            </button>
            <button
              onClick={() => setPagination(pagination + 1)}
              disabled={Math.ceil(data.length / 12) == pagination + 1}
              className={twMerge(
                "hover:text-gray-900 transition-all",
                Math.ceil(data.length / 12) == pagination + 1 &&
                  "text-gray-400 hover:text-gray-400"
              )}
            >
              <FaChevronCircleRight className="text-3xl" />
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Employees;
