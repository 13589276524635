import React, { useEffect, useState } from "react";
import { instance } from "utils/axios";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/partners`, // Your API endpoint to fetch partner data
    })
      .then((res) => {
      console.log(res.data);
        setPartners(res.data); // Assuming response data is an array of partner objects
      })
      .catch((err) => {
        console.error("Error fetching partners:", err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <section className="py-10">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
        <div className="col-span-12">
          <div className="text-center uppercase text-primary text-sm">
            Бидний түнш
          </div>
          <div className="text-center capitalize font-semibold text-2xl">
            Хамтрагчид
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-6 gap-6 col-span-12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            partners.map((partner, index) => (
              <div
                key={index}
                className="border border-transparent hover:border-stone-100 cursor-pointer p-6 rounded-xl group transition-all"
              >
                <img
                  className="aspect-square object-cover group-hover:scale-105 transition-all"
                  src={`${process.env.REACT_APP_CDN_URL}${partner.imageUrl}`} // Assuming `imageUrl` contains the path to the partner's image
                  // alt={partner.name}
                />
                <div className="text-center group-hover:font-bold transition-all">
                  {partner.name}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default Partners;
