import React from 'react';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { Link } from 'react-router-dom';
import { FaUsers } from 'react-icons/fa';

function numberToAbbreviation(num) {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'b';
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'm';
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'k';
    } else {
        return num.toString();
    }
}

function Card({data, index}) {    
    return (
      <div className="col-span-1 border rounded-2xl overflow-hidden bg-[#fffffff2] flex flex-col">
        {
          <SplideSlide className="relative" >
              <div className="aspect-[4/3]">
                <img base="travels" src={data.image} alt="travel" style={{ objectFit: "cover" }} className='aspect-[4/3] w-full' loading="lazy" fill = {true}/>
              </div>
              {
                data.type1 &&
                <div className='absolute top-2 right-2 px-3 py-1 text-sm bg-green-500 text-white rounded-xl max-w-[35%]'>{data.type1}</div>
              }
              {
                data.type2 &&
                <div className='absolute top-2 left-2 px-3 py-1 text-sm bg-green-500 text-white rounded-xl max-w-[70%]'>{data.type2}</div>
              }
              <span className='absolute bottom-2 left-4 px-3 py-1 font-semibold text-sm bg-morange text-white rounded-full'>{data? data.duration : data.en ? data.en.duration : data.mn ? data.mn.duration : ''}</span>
              {
                data.traveledNumbers &&
                <span className='absolute bottom-2 right-4 px-3 py-1 text-sm bg-morange text-white rounded-full flex items-center gap-2'>
                    <FaUsers size={17}/> {numberToAbbreviation(data.traveledNumbers)}
                </span>
              }
          </SplideSlide>
        }
        <div className="flex flex-col gap-4 py-4 px-6 justify-between flex-1">
          <div className="flex-1">
            <div>
              <h1 className="md:truncate font-bold md:text-lg text-secondary">{data? data.title : data.en ? data.en.title : data.mn ? data.mn.title : ''}</h1>
            </div>
            <div className='flex flex-col md:flex-row items-start md:items-center gap-2'>
              <p className='text-morange font-bold'>{data?.rating}</p>
            </div>
          </div>
          <div>
            <div className='flex justify-between mb-3'>
                {/* <div className='rounded font-semibold text-green-400 border border-green-400 px-2'>Best seller</div> */}
            </div>
            {/* <Link 
              className="bg-primary200 text-white font-medium py-3 w-full flex justify-center rounded-xl transition-all hover:brightness-95"
              href={`/${locale}/travel/${data.id}`}
              scroll={false}>
                Дэлгэрэнгүй
            </Link> */}
          </div>
        </div>
      </div>
    );
};

export default Card;
