import { IoCheckmarkDone } from "react-icons/io5"

const Services = () => {
  return (
    <section className="pt-10 pb-20">
      <div className="container mx-auto grid grid-cols-12 gap-6 px-6">
        <div className="col-span-12 mb-10">
          <div className="text-center uppercase text-primary text-sm">
            БОЛОВСРОЛ БА СУРГАЛТ
          </div>
          <div className="text-center capitalize font-semibold text-2xl mb-10">
            ДОТООД БА ГАДААД
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 mb-10 md:mb-0 max-w-[80vw]">
          <div className="grid grid-cols-2 gap-6 rotate-45">
            <div className="rotated-image flex justify-end items-end">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img
                  className="absolute w-full h-full object-cover -rotate-45"
                  src={`${process.env.REACT_APP_CDN_URL}/assets/images/tech/e4.png`}
                ></img>
              </div>
            </div>
            <div className="rotated-image flex items-end">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img
                  className="absolute w-full h-full object-cover -rotate-45"
                  src={`${process.env.REACT_APP_CDN_URL}/assets/images/tech/e3.png`}
                ></img>
              </div>
            </div>
            <div className="rotated-image flex justify-end items-start">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img
                  className="absolute w-full h-full object-cover -rotate-45"
                  src={`${process.env.REACT_APP_CDN_URL}/assets/images/tech/e2.png`}
                ></img>
              </div>
            </div>
            <div className="rotated-image flex justify-start items-start">
              <div className="w-[150px] max-w-[30vw] aspect-square relative overflow-hidden">
                <img
                  className="absolute w-full h-full object-cover -rotate-45"
                  src={`${process.env.REACT_APP_CDN_URL}/assets/images/tech/e1.png`}
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-xl text-primary" />
              Дүрс оношилгооны резидент эмч нарын сургалтыг гэрээт
              байгууллагуудтай хамтран явуулж байна.
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              Дүрс оношилгооны технологич нарт ажлын байран дээр суурилсан
              сургалтыг хийснээр боловсон хүчин чадавхижиж, шинжилгээний нэр
              төрөл олширч, зургийн чанар сайжирч байна.
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              Төрөлжсөн нарийн мэргэжлийн эмч дутагдалтай байгаа улсын болон
              хувийн хэвшлийн эмнэлэгүүдэд дүрс оношилгооны чиглэлээр зөвлөх
              үйлчилгээ үзүүлэн хамтран ажиллаж байна.
            </div>
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-3xl text-primary" />
              Манай төвийн эмч нар олон улсын мэргэжлийн хуралд жил бүр тогтмол
              оролцож, гадаадын эмнэлэгүүдэд богино ба урт хугацааны эмнэл зүйн
              дадлага хийж, өөрсдийн мэдлэг мэргэжлээ байнга дээшлүүлэхэд
              анхааран ажиллаж байна.
            </div>
         
            <div className="flex gap-3">
              <IoCheckmarkDone className="text-xl text-primary" />
              Бид цаашид магистр, докторын сургалтанд хамрагдагсдын тоог тогтмол
              нэмэгдүүлэн ажиллахыг зорьж байна.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services