import { NavLink } from "react-router-dom"
import { twMerge } from "tailwind-merge"

const Services = () => {
  return (
    <div>
      <section className="py-10 bg-primary">
        <div className="container mx-auto px-6">
          <div className="font-bold text-xl text-white">
            Services-uud
          </div>
        </div>
      </section>
      <div className="flex flex-col container mx-auto p-10">
        <NavLink
          to="/services/foo"
          className={({ isActive }) =>
            twMerge(
              "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
              isActive && "bg-slate-100 text-primary"
            )
          }
        >
          Бидний тухай
        </NavLink>
        <NavLink
          to="/services/foo1"
          className={({ isActive }) =>
            twMerge(
              "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
              isActive && "bg-slate-100 text-primary"
            )
          }
        >
          Бидний тухай
        </NavLink>
        <NavLink
          to="/services/foo2"
          className={({ isActive }) =>
            twMerge(
              "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
              isActive && "bg-slate-100 text-primary"
            )
          }
        >
          Бидний тухай
        </NavLink>
        <NavLink
          to="/services/foo3"
          className={({ isActive }) =>
            twMerge(
              "py-2 px-3 font-medium hover:bg-slate-100 transition-all rounded hover:text-primary",
              isActive && "bg-slate-100 text-primary"
            )
          }
        >
          Бидний тухай
        </NavLink>
      </div>
    </div>
  )
}

export default Services